import { getDateAt } from '@mca/shared/util';

export interface SystemConfig {
  defaultVenue: number;
  minDepositToMerchantAmount: number;
  minDepositLastToMerchantAmount: number;
  fixedFee: string;
  minLastPaymentAmount: number;
  sameDayAchTimeThreshold: number;
  nextDayAchTimeThreshold: number;
}

export const configTimeToDate = (value: number | string) => {
  const date = new Date();
  const midnight = getDateAt({ hour: 0, min: 0, sec: 0, ms: 0 });
  date.setTime(midnight.getTime() + +value);
  return date;
};

export const dateToConfigTime = (value: Date) => {
  const midnight = getDateAt({ hour: 0, min: 0, sec: 0, ms: 0 });
  return value.getTime() - midnight.getTime();
};
