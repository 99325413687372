export interface Bankruptcy {
  id?: number;
  key_dates: KeyDate[];
  case_number: string;
  status: string | null;
  chapter_type: string | null;
  filing_date: string | Date;
  impact_on_subject: string;
  note: string | null;
}

export class BankruptcyRec implements Bankruptcy {
  id?: number;
  key_dates = [];
  case_number = '';
  status = null;
  chapter_type = null;
  filing_date = '';
  impact_on_subject = '';
  note = null;
}

export interface KeyDate {
  id?: number;
  bankruptcy_id?: number;
  key_date: string;
  reminder_type: null;
  note: string;
}

export class KeyDateRec implements KeyDate {
  id?: number;
  bankruptcy_id?: number;
  key_date = '';
  reminder_type = null;
  note = '';
}
