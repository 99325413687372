import { Injectable, inject, signal } from '@angular/core';
import { first, shareReplay, tap } from 'rxjs/operators';
import { ApiService } from '@mca/shared/util';
import { httpVenues } from './shared-http-points';
import { SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AppConstantsService {
  private apiService = inject(ApiService);

  venues?: Record<number, string>;

  venueOptions = signal<SelectItem[]>([]);
  venueMap = signal<Record<number, string>>({});

  private venues$ = this.apiService.get<Record<number, string>>(httpVenues()).pipe(
    tap(venues => {
      this.venues = venues;
      this.venueOptions.set(Object.entries(venues).map(([key, value]) => ({ value: +key, label: value })));
      this.venueMap.set(venues);
    }),
    shareReplay(1),
  );

  getVenues() {
    return this.venues$.pipe(first());
  }

  isBasepointCollection(venue: number) {
    return ['UnitedFirst-BasePoint', 'WhiteRoad-BasePoint'].indexOf(this.venues?.[venue] ?? '') !== -1;
  }
}
