import { HolidaysService } from '@mca/references/api';
import { McaRateTypes, OCPayment, PaymentFrequency } from '@mca/shared/domain';
import { roundMoney } from '@mca/shared/util';
import { addWeeks, toDate, differenceInBusinessDays } from 'date-fns';
import { MCAProgramTypes } from '../../../entities/mca-consts';
import { McaScheduleState } from '../../../entities/mca-schedule';
import { MCARec } from '../../../entities/mcarec';
import { McaOffer } from '../../../entities/offer';

export const FUNDING_ROUDING = 1000;
export enum RateType {
  C = 0,
  D,
}

export class ScheduleFunctions {
  static copyScheduleState = (state: McaScheduleState, baseState?: McaScheduleState): McaScheduleState =>
    baseState
      ? { ...baseState, ...state, offerForm: { ...baseState.offerForm, ...state.offerForm } }
      : { ...state, offerForm: { ...state.offerForm } };

  static calcDiscount = (state: McaScheduleState) => {
    if (!state.exposureRec.totExposure || state.exposureRec.totExposure === 0) {
      return { trueRateDisc: 0, trueRateWithCommDisc: 0 };
    }
    const payment = roundMoney(state.expectedRet / (state.offerForm.days || state.offerForm.daysDisc || 1));
    const multDisc = state.offerForm.discountFactorRate - 1;
    const expectedRetDisc = state.offerForm.fundedAmt * state.offerForm.discountFactorRate;
    const daysDisc = Math.round(expectedRetDisc / payment);
    const trueRateWithCommDisc = state.comAmt
      ? (state.offerForm.fundedAmt * multDisc + state.exposureRec.totExposure) / (state.exposureRec.totExposureWithCom || 1)
      : undefined;
    const anulizedRetDisc = state.comAmt
      ? ((((state.expectedRetDisc - state.offerForm.fundedAmt - state.comAmt) / (state.exposure + state.comAmt)) * 250) / daysDisc) * 100
      : undefined;
    return {
      offerForm: { daysDisc },
      expectedRetDisc,
      trueRateDisc: (state.offerForm.fundedAmt * multDisc) / state.exposureRec.totExposure + 1,
      trueRateWithCommDisc,
      anulizedRetDisc,
    };
  };

  static calcTrueRate = (state: McaScheduleState) => {
    if (!state.exposureRec.totExposure || state.exposureRec.totExposure === 0) {
      return { trueRate: 0, trueRateWithComm: 0 };
    }
    const mult = state.offerForm.rate - 1;
    const trueRateWithComm = state.comAmt
      ? (state.offerForm.fundedAmt * mult + state.exposureRec.totExposure) / (state.exposureRec.totExposureWithCom || 1)
      : undefined;
    return {
      trueRate: (state.offerForm.fundedAmt * mult) / state.exposureRec.totExposure + 1,
      trueRateWithComm,
    };
  };

  static setupDayCountPayment = (state: McaScheduleState, rateType: RateType, fixedNumberOfPayments: number) => {
    const result = ScheduleFunctions.copyScheduleState(state);
    result.expectedRet = roundMoney(result.offerForm.fundedAmt * result.offerForm.rate);
    result.expectedRetDisc = roundMoney(result.offerForm.fundedAmt * result.offerForm.discountFactorRate);
    const fixedDays = fixedNumberOfPayments && Math.ceil(fixedNumberOfPayments * result.offerForm.selectedWithdFreq);
    if (result.offerForm.fixPayment || fixedDays) {
      if (result.offerForm.withdrAmt === 0) {
        throw Error('Withdraw amount is 0');
      }
      result.offerForm.days = fixedDays || Math.round(result.expectedRet / result.offerForm.withdrAmt);
      result.offerForm.daysDisc = fixedDays || Math.round(result.expectedRetDisc / result.offerForm.withdrAmt);
    } else {
      if (rateType === RateType.C) {
        if (result.offerForm.days === 0) {
          throw Error('# of days is 0');
        }
        result.offerForm.withdrAmt = roundMoney(result.expectedRet / result.offerForm.days);
        result.offerForm.daysDisc = Math.round(result.expectedRetDisc / result.offerForm.withdrAmt);
      } else {
        if (result.offerForm.daysDisc === 0) {
          throw Error('# of days is 0');
        }
        result.offerForm.withdrAmt = roundMoney(result.expectedRetDisc / result.offerForm.daysDisc);
        result.offerForm.days = result.offerForm.withdrAmt && Math.round(result.expectedRet / result.offerForm.withdrAmt);
      }
    }
    return result;
  };

  static calcSuggested = (state: McaScheduleState, mca: MCARec): McaScheduleState => {
    const result = ScheduleFunctions.copyScheduleState(state);

    Object.assign(result, ScheduleFunctions.calcOutstandingTotals(state.offerForm.program, mca));
    const feeRate = result.offerForm.fee / 100;
    const rawFA = result.totCurrentBalance / (1 - feeRate) + (result.offerForm.fixed_cf || 0);
    result.offerForm.fundedAmt = Math.ceil(rawFA / FUNDING_ROUDING) * FUNDING_ROUDING;
    result.feeAmt = result.offerForm.fixed_cf || result.offerForm.fundedAmt * feeRate;
    const isAllowedProgram = [MCAProgramTypes.consolidation, MCAProgramTypes.incrementalDeal].includes(state.offerForm.program);
    result.additionalAmt = isAllowedProgram ? roundMoney(result.offerForm.fundedAmt - result.feeAmt - result.totCurrentBalance) : 0;
    result.expectedRet = roundMoney(result.offerForm.fundedAmt * result.offerForm.rate);
    const suggestedPayment = roundMoney(result.totPayment * 0.8);
    if (result.offerForm.withdrAmt === 0) {
      result.offerForm.withdrAmt = suggestedPayment;
    }
    const suggestedDays = suggestedPayment ? Math.ceil(result.expectedRet / suggestedPayment) : 0;
    result.offerForm.days = result.offerForm.daysDisc = suggestedDays;
    result.savingsPct = ((result.totPayment - suggestedPayment) / result.totPayment) * 100;
    result.netToMerchantAmt = roundMoney(result.offerForm.fundedAmt - result.feeAmt - result.buyOutAmt);
    return result;
  };

  static applyDbaFixedFee(state: McaScheduleState, dbaFixedCF: number) {
    if (dbaFixedCF) {
      state.offerForm.fixed_cf = dbaFixedCF;
      state.offerForm.fee = 0;
      state.feeAmt = dbaFixedCF;
    }
    return state;
  }

  static calcLocals = (state: McaScheduleState, mca: MCARec) => {
    const result = ScheduleFunctions.copyScheduleState(state);
    result.offerForm.program = mca.position.program ?? MCAProgramTypes.deal;
    result.offerForm.rate = MCARec.getRateValue(mca, McaRateTypes.Contract);
    result.offerForm.discountFactorRate ||= MCARec.getRateValue(mca, McaRateTypes.Discount);
    if (mca.position.payment_amount) {
      if (result.offerForm.discountFactorRate) {
        result.offerForm.daysDisc = Math.round(
          ((mca.position.funding_amount ?? 0) * result.offerForm.discountFactorRate) / mca.position.payment_amount,
        );
      }
      result.offerForm.days = Math.round(((mca.position.funding_amount ?? 0) * result.offerForm.rate) / mca.position.payment_amount);
    }
    result.offerForm.withdrAmt = mca.position.payment_amount ?? 0;
    result.offerForm.fundedAmt = mca.position.funding_amount ?? 0;
    result.offerForm.fee = mca.position.fee_pct ?? 0;
    result.offerForm.fixed_cf = mca.position.fixed_cf ?? 0;

    result.offerForm.selectedWithdFreq = mca.position.payment_freq ?? result.offerForm.selectedWithdFreq;
    result.offerForm.switch_to_daily_payments = mca.position.switch_to_daily_payments ?? result.offerForm.switch_to_daily_payments;
    result.offerForm.selectedDepFreq = mca.depFreq ?? result.offerForm.selectedDepFreq;
    result.offerForm.depStartDate = mca.depStartDate ?? result.offerForm.depStartDate;
    result.offerForm.withdrStartDate = mca.paybackStartDate ?? result.offerForm.withdrStartDate;

    result.expectedRet = mca.totalConsPaidBack;
    result.expectedRet = mca.paybackAmount;

    Object.assign(result, ScheduleFunctions.calcOutstandingTotals(result.offerForm.program, mca));

    result.additionalAmt =
      result.offerForm.program === MCAProgramTypes.consolidation
        ? result.offerForm.fundedAmt - result.feeAmt - result.totCurrentBalance
        : 0;
    if (result.offerForm.fundedAmt !== 0) {
      result.feeAmt = result.offerForm.fixed_cf || (result.offerForm.fundedAmt * result.offerForm.fee) / 100;
    }
    result.netToMerchantAmt = roundMoney(result.offerForm.fundedAmt - result.feeAmt - result.buyOutAmt);
    return result;
  };

  static calcOfferDailyConsolidateDeposits(deposits: OCPayment[]) {
    const result = [];
    let accum = 0;
    let counter = 0;
    deposits.forEach(d => {
      accum += d.ammount;
      counter++;
      if (counter === PaymentFrequency.weekly) {
        result.push({ ammount: accum });
        counter = 0;
        accum = 0;
      }
    });
    if (counter > 0) {
      result.push({ ammount: accum });
    }
    return result;
  }

  static calcOutstandingTotals(program: MCAProgramTypes, mca: MCARec) {
    let totCurrentBalance = 0;
    let totPayment = 0;
    const isAllowedProgram = [MCAProgramTypes.consolidation, MCAProgramTypes.incrementalDeal].includes(program);
    mca.outstandingLoans.forEach(l => {
      if (l.consolidate && isAllowedProgram) {
        totCurrentBalance += Number(l.balanceRemains);
        totPayment += Number(l.payment);
      }
      totCurrentBalance = roundMoney(totCurrentBalance);
    });
    return { totCurrentBalance, totPayment };
  }

  static offerDataToScheduleState(state: McaScheduleState, offer: McaOffer) {
    let numPayments = offer.withdrawal?.reduce((acc, w) => acc + w.quantity, 0);
    const lastPayment = offer.withdrawal[offer.withdrawal.length - 1]?.amount ?? 0;
    const paymentAmount = offer.withdrawal_payment * offer.withdrawal_freq;
    if (offer.withdrawal_freq === PaymentFrequency.weekly && lastPayment < paymentAmount) {
      numPayments -= 1 - lastPayment / paymentAmount;
    }
    return {
      offerForm: {
        ...state.offerForm,
        program: offer.funding_type,
        withdrAmt: offer.withdrawal_payment,
        fundedAmt: offer.amount,
        rate: offer.rates[0].rate,
        discountFactorRate: offer.rates[1]?.rate,
        days: numPayments * (offer.rates[1]?.rate ? 0 : offer.withdrawal_freq),
        daysDisc: numPayments * (offer.rates[1]?.rate ? offer.withdrawal_freq : 0),
        fee: offer.contract_fee_pct,
        fixed_cf: offer.fixed_cf,
        fixPayment: offer.fixed_payment,
        selectedWithdFreq: offer.withdrawal_freq,
        selectedDepFreq: offer.deposit_freq,
        templateId: offer.template_id ?? null,
        switch_to_daily_payments: offer.switch_to_daily_payments,
      },
      expectedRet: offer.amount * offer.getRate(),
      // TODO: set this MCA values to MCA
      useACHForDeposits: offer.ach_for_deposits,
      useACHForPayments: offer.ach_for_payments,
    };
  }
}

export class GenerateWithdrawalsCommand {
  private withdrawals: OCPayment[] = [];
  private totalPayments = this.daysHint || Math.round(this.totalAmount / this.payment);

  constructor(
    private holidaysService: HolidaysService,
    private startDate: Date,
    private frequency: PaymentFrequency | 0,
    private totalAmount: number,
    private payment: number,
    private minLastPayment: number,
    private daysHint: number,
  ) {
    if (frequency === PaymentFrequency.weekly) {
      this.payment *= frequency;
      this.totalPayments = Math.round(this.totalPayments / frequency);
    }
  }

  execute() {
    this.generateWithdrawals();
    const resultDays = this.adjustLastPayment();
    return { withdrawals: this.withdrawals, days: resultDays };
  }

  private generateWithdrawals() {
    let nextDatePaymentsCount = 1;
    let weekCount = 1;
    let nextPaymentDate = this.holidaysService.forwardToBusinessDay(this.startDate);

    while (this.withdrawals.length < this.totalPayments) {
      this.generatePaymentsAtDate(nextPaymentDate, nextDatePaymentsCount);

      if (this.frequency === PaymentFrequency.daily) {
        ({ nextPaymentDate, nextDatePaymentsCount } = this.forwardHolidayPaymentsToBusinessDay(nextPaymentDate, nextPaymentDate));
      } else {
        nextPaymentDate = this.holidaysService.forwardToBusinessDay(addWeeks(this.startDate, weekCount));
        weekCount++;
      }
    }
  }

  private generatePaymentsAtDate(date: Date, count: number) {
    for (; count > 0; count--) {
      const isLastPayment = this.withdrawals.length === this.totalPayments - 1;
      if (isLastPayment) {
        this.payment = this.totalAmount;
        count = 1;
      }
      this.generatePayment(date, this.payment);
    }
  }

  private generatePayment(date: Date, amount: number) {
    const ocPayment: OCPayment = new OCPayment(undefined, 0, false, 0);
    ocPayment.effectivedate = toDate(date);
    ocPayment.ammount = roundMoney(amount);
    this.withdrawals.push(ocPayment);
    this.totalAmount -= amount;
  }

  private forwardHolidayPaymentsToBusinessDay(currentPaymentDate: Date, nextPaymentDate: Date) {
    const paymentDate = this.holidaysService.addBusinessDays(nextPaymentDate, PaymentFrequency.daily);
    const paymentsCount = differenceInBusinessDays(paymentDate, currentPaymentDate);
    return {
      nextPaymentDate: paymentDate,
      nextDatePaymentsCount: Math.min(this.totalPayments - this.withdrawals.length, paymentsCount),
    };
  }

  private adjustLastPayment() {
    const lastIncrementAmount = this.withdrawals[this.withdrawals.length - 1]?.ammount;
    let resultDays = this.daysHint || this.withdrawals.length * this.frequency;

    if (this.withdrawals.length > 1 && lastIncrementAmount && lastIncrementAmount < this.minLastPayment) {
      this.withdrawals[this.withdrawals.length - 2].ammount += lastIncrementAmount;
      this.withdrawals.pop();
      resultDays -= this.frequency;
    }

    return resultDays;
  }
}
