import { Injectable, inject, signal } from '@angular/core';
import { ApiService, LazyService } from '@mca/shared/util';
import { SelectItem } from 'primeng/api';
import {
  httpRefModule,
  httpRefModuleRecord,
  httpRefUserRegister,
  httpRefUserFields,
  httpRefUsers,
  httpRefDownload,
  httpExternalResourceStatuses,
  httpDocuSignStatuses,
  RefModule,
} from './references-http-points';
import { map, shareReplay, tap } from 'rxjs';
import { RecStatus } from '../entities/rec-status';
import { Person, UserLoginInfo } from '@mca/user/api';

export class ReferencesServiceConsts {
  _baknkAccountTypes: SelectItem<number>[] = [];
  _payDays: SelectItem[] = [];
  _commissionStrategy: SelectItem[] = [];
  amountbaseindicatorFA = -1;
  amountbaseindicatorRTR = -1;
  amountbaseindicatorProfit = -1;
  _amountbaseindicator: any[] = [];
}

export interface DocuSignStatuses {
  generated_date_time: string;
  id: number;
  legal_name: string;
  mcaid: number;
  offer_id: number;
  recipients: {
    email: string;
    id: number;
    status: number;
  }[];
  status: number;
}

@Injectable({
  providedIn: 'root',
})
export class ReferencesService {
  private apiService = inject(ApiService);
  private lazyService = inject(LazyService);

  referencesServiceConsts = new ReferencesServiceConsts();

  statusIdMap = signal({} as Record<number, RecStatus>);
  statusLabelMap = signal({} as Record<string, RecStatus>);

  get users() {
    return this.lazyService.getCache<(Person & UserLoginInfo)[]>('ref-users') ?? [];
  }

  private refConsts$ = this.apiService.get(httpRefModule('menuconsts')).pipe(
    tap(data => {
      this.referencesServiceConsts._baknkAccountTypes = data.bankAccountTypes;
      this.referencesServiceConsts._payDays = data.payDays;
      this.referencesServiceConsts._commissionStrategy = data.commissionStrategy;

      this.referencesServiceConsts._amountbaseindicator = data.amountbaseindicator;
      this.referencesServiceConsts._amountbaseindicator.forEach(i => {
        switch (i.label) {
          case 'FA':
            this.referencesServiceConsts.amountbaseindicatorFA = i.value;
            break;
          case 'RTR':
            this.referencesServiceConsts.amountbaseindicatorRTR = i.value;
            break;
          case 'TP':
            this.referencesServiceConsts.amountbaseindicatorProfit = i.value;
            break;
        }
      });
    }),
    map(() => ({ ...this.referencesServiceConsts })),
    shareReplay(1),
  );

  listReferenceRecord<T = any>(moduleName: RefModule) {
    return this.apiService.get<T>(httpRefModule(moduleName));
  }

  getReferenceRecord(moduleName: RefModule, id: string | number) {
    return this.apiService.get(httpRefModuleRecord(moduleName, id));
  }

  deleteReferenceRecord(moduleName: RefModule, id: number) {
    return this.apiService.delete(httpRefModuleRecord(moduleName, id));
  }

  deleteReferenceRecordBy(moduleName: RefModule, body: any) {
    return this.apiService.delete(httpRefModule(moduleName), { body });
  }

  updateReferenceRecord(moduleName: RefModule, id: string | number, body: any) {
    if (moduleName === 'users') {
      this.lazyService.deleteCache('ref-users');
    }
    if (moduleName === 'questions') {
      return this.apiService.put(httpRefModuleRecord(moduleName, id), body);
    }
    return this.apiService.post(httpRefModuleRecord(moduleName, id), body);
  }

  createReferenceRecord(moduleName: RefModule, body: any) {
    if (moduleName === 'users') {
      this.lazyService.deleteCache('ref-users');
    }
    if (moduleName === 'questions') {
      return this.apiService.post(httpRefModule(moduleName), body);
    }
    return this.apiService.put(httpRefModule(moduleName), body);
  }

  userRegisterConfRequest(id: number) {
    return this.apiService.get(httpRefUserRegister(id));
  }

  getUserFields(id: number, fields: string[]) {
    return this.apiService.post(httpRefUserFields(id), fields);
  }

  getUsers() {
    return this.lazyService.loadOnce<(Person & UserLoginInfo)[]>('ref-users', () => this.apiService.get(httpRefUsers()));
  }

  getStatuses() {
    return this.lazyService
      .loadOnce<RecStatus[]>('ref-mca-status', () => this.apiService.get(httpRefModule('mcastatus')))
      .pipe(
        tap(statuses => {
          this.statusLabelMap.set(
            statuses.reduce((acc: Record<string, RecStatus>, v: RecStatus) => Object.assign(acc, { [v.status]: v }), {}),
          );
          this.statusIdMap.set(statuses.reduce((acc: Record<number, RecStatus>, v: RecStatus) => Object.assign(acc, { [v.id]: v }), {}));
        }),
      );
  }

  getStatusLabel(id: number): string {
    return this.statusIdMap()?.[id]?.status ?? '';
  }
  getStatusId(label: string): number {
    return this.statusLabelMap()?.[label]?.id ?? 0;
  }

  getStatusReasons() {
    return this.lazyService.loadOnce('ref-status-reasons', () => this.apiService.get(httpRefModule('statusreasonstruct')));
  }

  getBusinessTypes() {
    return this.lazyService.loadOnce('ref-business-types', () => this.apiService.get(httpRefModule('businesstypes')));
  }

  getOwnershipTitles() {
    return this.lazyService.loadOnce('ref-owner-titles', () => this.apiService.get(httpRefModule('ownertitle')));
  }

  getBankruptcyOptions() {
    return this.lazyService.loadOnce('ref-bankruptcy-options', () => this.apiService.get(httpRefModule('bankruptcy_allowed_values')));
  }

  downloadFile(comp: string, id: number) {
    return this.apiService.loadFile(httpRefDownload(comp, id));
  }

  getBankAccountTypes() {
    return this.getConsts().pipe(map(res => res._baknkAccountTypes));
  }

  getConsts() {
    return this.refConsts$;
  }

  getExternalResourceStatuses() {
    return this.apiService.get(httpExternalResourceStatuses());
  }

  getDocuSignStatuses(params: { startDate: string; endDate: string }) {
    return this.apiService.get<DocuSignStatuses[]>(httpDocuSignStatuses(), { params });
  }
}
